import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import media from "styled-media-query";

import MenuIcon from "./menuIcon";
import SearchIcon from "./searchIcon";
import Nav from "./Nav";
import Headroom from "react-headroom";
import Close from "./close";
import Logo from "./logo";
// import Mark from "./mark";

const HeaderWrapper = styled.div`
    position: relative;
    z-index: 3;
`;

const SiteHeader = styled.div`
    display: grid;
    width: 100%;
    margin: 0 auto;
    padding-right: 25px;
    padding-left: 25px;
    grid-auto-flow: column;
    grid-column-gap: 15px;
    grid-template-columns:
        minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr)
        minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(
            1px,
            1fr
        )
        minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    align-items: center;
    align-content: center;
    height: 60px;
    position: relative;
    z-index: 999;

    @media only screen and (min-device-width: 813px) {
        max-width: 1640px;
        width: 100%;
        padding-right: 50px;
        padding-left: 50px;
        grid-column-gap: 25px;
        height: 100px;
    }

    ${media.lessThan("medium")`
        .menu-left,
        .menu-right {
            display: none;
        }
    `}
`;

const ExpandedNav = styled.div`
    background-color: #e0e0e0;
`;

const LogoLeft = styled.div`
    grid-column: 1/1;
    cursor: pointer;
    height: 18px;
    width: 18px;

    div,
    svg {
        width: 18px;
        height: 18px;

        path {
            fill: var(--black);
        }
    }

    ${media.greaterThan("medium")`
        display: none;
    `}
`;

const SearchRight = styled.div`
    grid-column: 13/13;
    cursor: pointer;
    height: 18px;
    width: 18px;

    div,
    svg {
        width: 18px;
        height: 18px;

        path {
            fill: var(--black);
        }
    }

    ${media.greaterThan("medium")`
        display: none;
    `}
`;

const LogoCenter = styled.div`
    grid-column: 3/12;
    align-self: center;
    height: 16px;

    svg {
        height: 16px;
        width: auto;
        fill: var(--black);

        ${media.lessThan("large")`
            height: 100%;
        `}
    }

    a {
        display: block;
        text-align: center;
    }

    @media only screen and (min-device-width: 813px) {
        grid-column: 6 / 8;
        align-self: center;
        height: 20px;

        svg {
            height: 20px;
            width: auto;
        }

        a {
            display: block;
        }
    }
`;

class Header extends Component {
    state = {
        modal: false,
        hovered: false,
    };
    setHover = () => this.setState({ hovered: true });
    cancelHover = () => this.setState({ hovered: false });

    openModal = () => {
        const { modal } = this.state;
        if (!modal) {
            this.setState({ modal: true });
        }
    };

    closeModal = () => {
        const { modal } = this.state;
        if (modal) {
            this.setState({ modal: false });
        }
    };

    render() {
        const { content_color } = this.props;
        return (
            <HeaderWrapper>
                <div>
                    <Headroom className="header-headroom">
                        <div
                            className={`header-container header-${content_color}`}>
                            <div className="header-container-background">
                                <SiteHeader>
                                    <LogoLeft
                                        onClick={this.openModal}
                                        className="show-link"
                                    >
                                        <MenuIcon color={content_color} />
                                    </LogoLeft>
                                    <div
                                        className={`menu-left menu-${content_color}`}>
                                        <div className="menu-item current first">
                                            <div className="menu-item-wrapper w-dropdown">
                                                <div className="nav-menu-link w-dropdown-toggle">
                                                    <div className="caption">
                                                        <img
                                                            src={require("../../images/icons/icon__globe.svg")}
                                                            alt="daily overview"
                                                            className="menu-item__icon"
                                                        />
                                                        Explore
                                                    </div>
                                                </div>
                                                <nav className="submenu">
                                                    <Link
                                                        to="/stories"
                                                        className="dropdown-link caption w-dropdown-link">
                                                        <span>Stories</span>
                                                    </Link>
                                                    <Link
                                                        to="/daily"
                                                        className="dropdown-link caption w-dropdown-link">
                                                        <span>Daily</span>
                                                    </Link>
                                                    <Link
                                                        to="/digital-index"
                                                        className="dropdown-link caption w-dropdown-link">
                                                        <span>Index</span>
                                                    </Link>
                                                    <Link
                                                        to="/map"
                                                        className="dropdown-link caption w-dropdown-link">
                                                        <span>Map</span>
                                                    </Link>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="menu-item current">
                                            <div className="menu-item-wrapper w-dropdown">
                                                <div className="nav-menu-link w-dropdown-toggle">
                                                    <div className="caption">
                                                        <Link to="/shop">
                                                            <img
                                                                src={require("../../images/icons/icon__cart.svg")}
                                                                alt="daily overview"
                                                                className="menu-item__icon"
                                                            />
                                                            Shop
                                                        </Link>
                                                    </div>
                                                </div>
                                                <nav
                                                    className={`submenu drink-${content_color}`}>
                                                    <Link
                                                        to="/shop/books"
                                                        className="dropdown-link caption w-dropdown-link">
                                                        <span>Books</span>
                                                    </Link>
                                                    <Link
                                                        to="/shop/prints"
                                                        className="dropdown-link caption w-dropdown-link">
                                                        <span>Prints</span>
                                                    </Link>
                                                    <Link
                                                        to="/custom"
                                                        className="dropdown-link caption w-dropdown-link">
                                                        <span>Custom</span>
                                                    </Link>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>

                                    <LogoCenter>
                                        <Link to="/">
                                            <Logo color={content_color} />
                                        </Link>
                                    </LogoCenter>

                                    <div
                                        className={`menu-right menu-${content_color}`}>
                                        {/*<div className="menu-item current">
                                            <div className="menu-item-wrapper w-dropdown">
                                                <div className="nav-menu-link w-dropdown-toggle">
                                                    <div className="caption">
                                                    <img
                                                        src={require("../../images/icons/icon__mail.svg")}
                                                        alt="daily overview"
                                                        className="menu-item__icon"
                                                    />
                                                    Contact
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                        <div className="menu-item current">
                                            <div className="menu-item-wrapper w-dropdown">
                                                <div className="nav-menu-link w-dropdown-toggle">
                                                    <div className="caption">
                                                        <Link to="/about">
                                                            <img
                                                                src={require("../../images/icons/icon__info.svg")}
                                                                alt="daily overview"
                                                                className="menu-item__icon"
                                                            />
                                                            About
                                                        </Link>
                                                    </div>
                                                </div>
                                                <nav className="submenu">
                                                    <Link
                                                        to="/collaborations"
                                                        className="dropdown-link caption w-dropdown-link">
                                                        <span>Projects</span>
                                                    </Link>
                                                    <Link
                                                        to="/press"
                                                        className="dropdown-link caption w-dropdown-link">
                                                        <span>Press</span>
                                                    </Link>
                                                    <Link
                                                        to="/contact"
                                                        className="dropdown-link caption w-dropdown-link">
                                                        <span>Contact</span>
                                                    </Link>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="menu-item current last">
                                            <div className="menu-item-wrapper w-dropdown">
                                                <div className="nav-menu-link w-dropdown-toggle">
                                                    <div className="caption">
                                                        <Link to={`/digital-index?query=&page=1&configure%5BhitsPerPage%5D=30`}>
                                                            <img
                                                                src={require("../../images/icons/icon__search.svg")}
                                                                alt="daily overview"
                                                                className="menu-item__icon"
                                                            />
                                                            Search
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <SearchRight
                                        onClick={this.openModal}
                                        className="show-link"
                                    >
                                        <Link to={`/digital-index?query=&page=1&configure%5BhitsPerPage%5D=30`}>
                                            <SearchIcon color={content_color} />
                                        </Link>
                                    </SearchRight>

                                </SiteHeader>
                                {/* <Cart className="Header__summary snipcart-summary snipcart-checkout"><CartIcon /> 
                                </Cart>*/}
                            </div>
                        </div>
                    </Headroom>
                    {this.state.modal ? (
                        <ExpandedNav className="menu-modal">
                            <Nav />
                            <div
                                className="close-modal"
                                onClick={this.closeModal}>
                                <Close />
                            </div>
                        </ExpandedNav>
                    ) : null}
                </div>
            </HeaderWrapper>
        );
    }
}

export default Header;
