import React from "react";
import styled from "styled-components"

const MenuIconSVG = styled.div `
    width: 25px;
    height: 25px;
    transition: 100ms ease-in transform;
    transform: scale(1.2);

    &:hover {
        transform: scale(1.5);
    }
`

const menuIcon = props => (
    <MenuIconSVG>
        <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.6667 15.6667H0V13.0556H15.6667V15.6667ZM23.5 9.13889H0V6.52778H23.5V9.13889ZM15.6667 2.61111H0V0H15.6667V2.61111Z" fill="black"/>
        </svg>
    </MenuIconSVG>
);

export default menuIcon;

