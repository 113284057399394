import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";



const TemplateWrapper = (props) => {
    return (
        <StaticQuery
            query={graphql`
                query LayoutQuery {
                    datoCmsSite {
                        globalSeo {
                            siteName
                            titleSuffix
                            twitterAccount
                            facebookPageUrl
                            fallbackSeo {
                                title
                                description
                                twitterCard
                            }
                        }
                        faviconMetaTags {
                            ...GatsbyDatoCmsFaviconMetaTags
                        }
                    }
                    datoCmsHome {
                        id
                        introText
                        seoMetaTags {
                        ...GatsbyDatoCmsSeoMetaTags
                        }
                    }
                }
            `}
            render={data => (
                
                <div 
                className={props.parent}
                >
                    <HelmetDatoCms
                        seo={data.datoCmsHome.seoMetaTags}
                        favicon={data.datoCmsSite.faviconMetaTags}
                        title={data.datoCmsSite.globalSeo.fallbackSeo.title}
                        meta={[
                          {
                            name: 'description',
                            content: data.datoCmsSite.globalSeo.fallbackSeo.description,
                          },
                        ]}
                        
                    />
                    
                        {props.children}
                    
                </div>
            )}
        />
    )
};

TemplateWrapper.propTypes = {
    children: PropTypes.object
};

export default TemplateWrapper;
