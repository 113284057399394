import React from "react";
import styled from "styled-components"

const CloseLink = styled.div `
    width: auto;
    cursor: pointer;

    transition: 100ms ease-in transform;
    &:hover {
        transform: scale(1.1);
    }
`

const Close = props => (
<CloseLink>
<svg width="20px" height="20px" viewBox="0 0 20 20">
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Prints-Buy-2-Copy" transform="translate(-688.000000, -1572.000000)" fill="#000000">
            <polygon id="Fill-1" points="706.332811 1572 697.999687 1580.33299 689.667189 1572 688 1573.66716 696.332497 1582.00016 688 1590.33252 689.666876 1592 689.667503 1592 697.999687 1583.66732 706.332497 1592 706.333124 1592 708 1590.33252 699.667189 1582.00016 708 1573.66716"></polygon>
        </g>
    </g>
</svg>
</CloseLink>
);
export default Close;

