import React from "react";
import styled from "styled-components"

const SearchIconSVG = styled.div `
    width: 25px;
    height: 25px;
    transition: 100ms ease-in transform;
    transform: scale(1.2);

    &:hover {
        transform: scale(1.5);
    }
`

const SearchIcon = props => (
    <SearchIconSVG>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1 19.6607L2.33932 21L7.83834 15.501C9.22289 16.5962 10.9726 17.25 12.875 17.25C17.3623 17.25 21 13.6123 21 9.125C21 4.63769 17.3623 1 12.875 1C8.38769 1 4.75 4.63769 4.75 9.125C4.75 11.0274 5.40383 12.7771 6.49903 14.1617L1 19.6607ZM12.8559 15.3941C9.40413 15.3941 6.60591 12.5959 6.60591 9.14409C6.60591 5.69231 9.40413 2.89409 12.8559 2.89409C16.3077 2.89409 19.1059 5.69231 19.1059 9.14409C19.1059 12.5959 16.3077 15.3941 12.8559 15.3941Z" fill={props.color}/>
            <path d="M1 19.6607L0.646446 20.0142L0.292892 19.6607L0.646446 19.3071L1 19.6607ZM2.33932 21L2.69287 21.3536L2.33932 21.7071L1.98576 21.3536L2.33932 21ZM7.83834 15.501L7.48479 15.1474L7.79949 14.8327L8.14854 15.1088L7.83834 15.501ZM6.49903 14.1617L6.89117 13.8515L7.16728 14.2005L6.85258 14.5152L6.49903 14.1617ZM1.35355 19.3071L2.69287 20.6464L1.98576 21.3536L0.646446 20.0142L1.35355 19.3071ZM1.98576 20.6464L7.48479 15.1474L8.19189 15.8545L2.69287 21.3536L1.98576 20.6464ZM8.14854 15.1088C9.44794 16.1367 11.0892 16.75 12.875 16.75V17.75C10.856 17.75 8.99783 17.0557 7.52815 15.8931L8.14854 15.1088ZM12.875 16.75C17.0862 16.75 20.5 13.3362 20.5 9.125H21.5C21.5 13.8885 17.6385 17.75 12.875 17.75V16.75ZM20.5 9.125C20.5 4.91383 17.0862 1.5 12.875 1.5V0.5C17.6385 0.5 21.5 4.36154 21.5 9.125H20.5ZM12.875 1.5C8.66383 1.5 5.25 4.91383 5.25 9.125H4.25C4.25 4.36154 8.11154 0.5 12.875 0.5V1.5ZM5.25 9.125C5.25 10.9108 5.86332 12.5521 6.89117 13.8515L6.10688 14.4719C4.94433 13.0022 4.25 11.144 4.25 9.125H5.25ZM6.85258 14.5152L1.35355 20.0142L0.646446 19.3071L6.14547 13.8081L6.85258 14.5152ZM12.8559 15.8941C9.12799 15.8941 6.10591 12.872 6.10591 9.14409H7.10591C7.10591 12.3197 9.68027 14.8941 12.8559 14.8941V15.8941ZM6.10591 9.14409C6.10591 5.41617 9.12799 2.39409 12.8559 2.39409V3.39409C9.68027 3.39409 7.10591 5.96845 7.10591 9.14409H6.10591ZM12.8559 2.39409C16.5838 2.39409 19.6059 5.41617 19.6059 9.14409H18.6059C18.6059 5.96845 16.0315 3.39409 12.8559 3.39409V2.39409ZM19.6059 9.14409C19.6059 12.872 16.5838 15.8941 12.8559 15.8941V14.8941C16.0315 14.8941 18.6059 12.3197 18.6059 9.14409H19.6059Z" fill={props.color}/>
            </svg>
    </SearchIconSVG>
);

export default SearchIcon;

