import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Text, { Grid } from "../../Theme";
import media from "styled-media-query";

const NavGridCol = styled.div`
    padding-top: 30px;
    margin-bottom: 30px;
    align-self: start;
    grid-column: span 4;
    text-align: left;
    max-width: 100%;

    .subtitle {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 1rem;
    }
`;

const NavGrid = styled.div`
    ${Grid};

    ${media.lessThan("medium")`
        display: block;
        padding: 30px;
        padding-bottom: 80px;
    `}

    ${media.greaterThan("medium")`
        font-size: 16px;
        line-height: 24px;
        height: 100vh;
    `}

    grid-template-rows: 1fr 100px;
    align-content: start;
    justify-content: start;
    grid-auto-flow: row;

    ul {
        grid-column: span 4;
        padding: 0;
        margin: 0;
        align-self: start;
    }
`;

const Nav = () => (
    <NavGrid>
        <NavGridCol>
            <Text className="subtitle" sub>Explore</Text>
            <h2>
                <Link className="main-nav-link" to="/stories">
                    <span>Stories</span>
                </Link>
            </h2>
            <h2>
                <Link className="main-nav-link" to="/daily">
                    Daily
                </Link>
            </h2>
            <h2>
                <Link className="main-nav-link" to="/digital-index">
                    Index
                </Link>
            </h2>
            <h2>
                <Link className="main-nav-link" to="/map">
                    Map
                </Link>
            </h2>
        </NavGridCol>
        <NavGridCol>
            <Text className="subtitle" sub>
                <Link to="/shop">Shop</Link>
            </Text>
            <h2>
                <Link className="main-nav-link" to="/shop/books">
                    Books
                </Link>
            </h2>
            <h2>
                <Link className="main-nav-link" to="/shop/prints">
                    Prints
                </Link>
            </h2>
            <h2>
                <Link to="/custom" className="main-nav-link">
                    Custom
                </Link>
            </h2>
        </NavGridCol>
        <NavGridCol>
            <Text className="subtitle" sub>
                <Link to="/about">About</Link>
            </Text>
            <h2>
                <Link className="main-nav-link" to="/collaborations">
                    Projects
                </Link>
            </h2>
            <h2>
                <Link className="main-nav-link" to="/press">
                    Press
                </Link>
            </h2>
            <h2>
                <Link className="main-nav-link" to="/contact">
                    Contact
                </Link>
            </h2>
        </NavGridCol>

        <Text body col="span 4">
            <a
                href="https://www.instagram.com/dailyoverview/"
                target="_blank"
                rel="noopener noreferrer">
                Instagram
            </a>
        </Text>
        <Text body col="span 4">
            <a
                href="https://www.facebook.com/doverview/"
                target="_blank"
                rel="noopener noreferrer">
                Facebook
            </a>
        </Text>
        <Text body col="span 4">
            <a
                href="https://www.twitter.com/doverview/"
                target="_blank"
                rel="noopener noreferrer">
                Twitter
            </a>
        </Text>
    </NavGrid>
);

export default Nav;
