import React from "react";
import styled, { css } from "styled-components";
import media from "styled-media-query";

const GridStyles = css`
    display: block;
    width: 100%;
    margin: 0 auto;
    padding-right: 25px;
    padding-left: 25px;
    grid-auto-flow: column;
    grid-column-gap: 15px;
    grid-template-columns:
        minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr)
        minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(
            1px,
            1fr
        )
        minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    @media only screen and (min-device-width: 813px) {
        padding-right: 50px;
        padding-left: 50px;
        grid-column-gap: 50px;
        max-width: 1640px;
        width: 100%;
        display: grid;
    }
`;

export const Grid = css`
    ${GridStyles};
    align-items: center;
    align-content: center;
`;

export const MaxGrid = css`
    ${GridStyles};
    align-items: center;
    align-content: center;
    @media only screen and (min-device-width: 813px) {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
`;

const SectionStyles = css`
    ${GridStyles};
    position: relative;
    align-items: ${(props) => {
        if (props.top) return "start";
        if (props.bottom) return "end";
        if (props.baseline) return "baseline";
        return "center";
    }};
    padding-top: ${(props) => {
        if (props.stacked) return "0";
        if (props.xsmall) return "40px";
        if (props.small) return "60px";
        if (props.large) return "160px";
        if (props.full) return "35vh";
        return "0";
    }};
    padding-bottom: ${(props) => {
        if (props.xsmall) return "40px";
        if (props.small) return "60px";
        if (props.large) return "160px";
        if (props.full) return "35vh";
        return "0";
    }};
    ${media.lessThan("large")`
        padding-bottom: ${(props) => {
            if (props.xsmall) return "40px";
            if (props.small) return "40px";
            if (props.large) return "80px";
            if (props.full) return "100px";
            return "0";
        }};
        padding-top: ${(props) => {
            if (props.stacked) return "0";
            if (props.xsmall) return "40px";
            if (props.small) return "40px";
            if (props.large) return "80px";
            if (props.full) return "100px";
            return "0";
        }};
    `}
`;

const TypeStyles = css`
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: ${(props) => props.noMargin && "0"};
    color: ${(props) => props.color};

    grid-column: ${(props) => {
        if (props.mobileCol) return props.mobileCol;

        return "1/13";
    }};

    grid-row: ${(props) => {
        if (props.mobileRow) return props.mobileRow;
    }};

    text-align: ${(props) => {
        if (props.centerMobile) return "center";
        if (props.rightMobile) return "right";
        return "left";
    }};
    max-width: 100%;
    @media only screen and (min-device-width: 813px) {
        grid-column: ${(props) => props.col};
        grid-row: ${(props) => props.row};

        text-align: ${(props) => {
            if (props.center) return "center";
            if (props.right) return "right";
            return "left";
        }};
    }
`;

const PageSection = styled.section`
    ${SectionStyles};
`;

const HeadlineUltra = styled.h1`
    ${TypeStyles};
    font-family: nhp, sans-serif;
    

    ${media.lessThan("medium")`
    `}
    ${media.between("medium", "large")`
    `}
    ${media.between("large", "huge")`
    `}
    ${media.greaterThan("huge")`
    `}

    ${media.lessThan("medium")`
        font-size: 36px;
        line-height: 42px;
    `}

    ${media.between("medium", "large")`
        font-size: 36px;
        line-height: 48px;
    `}

    ${media.between("large", "huge")`
        font-size: 64px;
        line-height: 96px;
    `}

    ${media.greaterThan("huge")`
        font-size: 64px;
        line-height: 96px;
    `}

    em {
        font-family: lt, 'Times New Roman', sans-serif;
        font-style: italic;
    }
    a {
        color: ${(props) => props.color};
    }
`;
const HeadlineUltraSerif = styled.h1`
    font-size: 64px;
    line-height: 96px;
    font-family: lt, 'Times New Roman', sans-serif;
    ${TypeStyles};
    
    ${media.lessThan("medium")`
        font-size: 24px;
        line-height: 32px;
    `}
    ${media.between("medium", "large")`
        font-size: 36px;
        line-height: 48px;
    `}

    ${media.between("large", "huge")`
        font-size: 64px;
        line-height: 96px;
    `}

    ${media.greaterThan("huge")`
        font-size: 64px;
        line-height: 96px;
    `}
`;

const HeadlineRegular = styled.h1`
    font-size: 52px;
    line-height: 79px;
    font-family: nhp, sans-serif;
    ${TypeStyles};
    @media (max-width: 480px) {
        font-size: 40px;
    }
    em {
        font-family: lt, "Times New Roman", sans-serif;
        font-style: italic;
    }
`;
const HeadlineSerif = styled.h1`
    font-size: 52px;
    line-height: 79px;
    font-family: lt, 'Times New Roman', sans-serif;
    ${TypeStyles};
    
    ${media.lessThan("medium")`
        font-size: 24px;
        line-height: 36px;
    `}
    ${media.between("medium", "large")`
        font-size: 32px;
        line-height: 42px;
    `}

    ${media.between("large", "huge")`
        font-size: 42px;
        line-height: 56px;
    `}

    ${media.greaterThan("huge")`
    font-size: 52px;
    line-height: 79px;
    `}
    em {
        font-family: lt, 'Times New Roman', sans-serif;
        font-style: italic;
        display: block;
        margin-top: 4px;
    }
`;

const SubHeadline = styled.h2`
    ${media.lessThan("medium")`
    font-size: 24px;
    line-height: 36px;
    `}
    ${media.between("medium", "large")`
    font-size: 24px;
    line-height: 36px;
    `}
    ${media.greaterThan("large")`
    font-size: 32px;
    line-height: 48px;
    `}
    
    ${TypeStyles};
    em {
        font-family: lt, 'Times New Roman', sans-serif;
        font-style: italic;
    }
    
`;

const CaptionHeadline = styled.h3`
    font-family: am, sans-serif;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    ${TypeStyles};
`;

const BodyCopy = styled.p`
    font-size: 24px;
    line-height: 38px;
    font-family: nhp, sans-serif;
    ${TypeStyles};

   ${media.lessThan("medium")`
        font-size: 18px;
        line-height: 24px;
   `}    
`;

const BodyCopySmall = styled.p`
    font-family: lt, "Times New Roman", sans-serif;
    font-size: 14px;
    line-height: 24px;
    ${TypeStyles};
`;

const CustomHeadline = styled(SubHeadline)`
   ${media.lessThan("large")`
       grid-column: 1/13;
   `}
`;

export const Section = ({
    top,
    bottom,
    baseline,
    small,
    large,
    full,
    ...props
}) => {
    return (
        <PageSection
            top={top}
            bottom={bottom}
            baseline={baseline}
            small={small}
            large={large}
            full={full}
            {...props}
        />
    );
};

const Text = ({
    ultra,
    ultraSerif,
    headlineSerif,
    sub,
    caption,
    body,
    small,
    noMargin,
    right,
    rightMobile,
    center,
    centerMobile,
    color,
    row,
    mobileRow,
    col,
    mobileCol,
    dangerouslySetInnerHTML,
    ...props
}) => {
    if (sub)
        return (
            <SubHeadline
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                row={row}
                mobileRow={mobileRow}
                col={col}
                mobileCol={mobileCol}
                color={color}
                noMargin={noMargin}
                right={right}
                rightMobile={rightMobile}
                center={center}
                centerMobile={centerMobile}
                {...props}
            />
        );
    if (caption)
        return (
            <CaptionHeadline
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                row={row}
                mobileRow={mobileRow}
                col={col}
                mobileCol={mobileCol}
                color={color}
                noMargin={noMargin}
                right={right}
                rightMobile={rightMobile}
                center={center}
                centerMobile={centerMobile}
                {...props}
            />
        );
    if (ultra)
        return (
            <HeadlineUltra
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                row={row}
                mobileRow={mobileRow}
                col={col}
                mobileCol={mobileCol}
                color={color}
                noMargin={noMargin}
                right={right}
                rightMobile={rightMobile}
                center={center}
                centerMobile={centerMobile}
                {...props}
            />
        );
    if (ultraSerif)
        return (
            <HeadlineUltraSerif
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                row={row}
                mobileRow={mobileRow}
                col={col}
                mobileCol={mobileCol}
                color={color}
                noMargin={noMargin}
                right={right}
                rightMobile={rightMobile}
                center={center}
                centerMobile={centerMobile}
                {...props}
            />
        );
    if (headlineSerif)
        return (
            <HeadlineSerif
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                row={row}
                mobileRow={mobileRow}
                col={col}
                mobileCol={mobileCol}
                color={color}
                noMargin={noMargin}
                right={right}
                rightMobile={rightMobile}
                center={center}
                centerMobile={centerMobile}
                {...props}
            />
        );
    if (body)
        return (
            <BodyCopy
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                row={row}
                mobileRow={mobileRow}
                col={col}
                mobileCol={mobileCol}
                color={color}
                noMargin={noMargin}
                right={right}
                rightMobile={rightMobile}
                center={center}
                centerMobile={centerMobile}
                {...props}
            />
        );
    if (small)
        return (
            <BodyCopySmall
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                row={row}
                mobileRow={mobileRow}
                col={col}
                mobileCol={mobileCol}
                color={color}
                noMargin={noMargin}
                right={right}
                rightMobile={rightMobile}
                center={center}
                centerMobile={centerMobile}
                {...props}
            />
        );
    return (
        <HeadlineRegular
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            row={row}
            mobileRow={mobileRow}
            col={col}
            mobileCol={mobileCol}
            color={color}
            noMargin={noMargin}
            right={right}
            rightMobile={rightMobile}
            center={center}
            centerMobile={centerMobile}
            {...props}
        />
    );
};

export default Text;
